import React from 'react';
import ReactDOM from 'react-dom/client';
import { hydrate , render } from 'react-dom'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactPixel from 'react-facebook-pixel'


if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  Node.prototype.removeChild = function(child) {
    if (child.parentNode !== this) {
      if (console) {
        console.error('Cannot remove a child from a different parent', child, this);
      }
      return child;
    }
    return originalRemoveChild.apply(this, arguments);
  }

  const originalInsertBefore = Node.prototype.insertBefore;
  Node.prototype.insertBefore = function(newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        console.error('Cannot insert before a reference node from a different parent', referenceNode, this);
      }
      return newNode;
    }
    return originalInsertBefore.apply(this, arguments);
  }
}


const root = ReactDOM.createRoot(document.getElementById('root'));

if(process.env.NODE_ENV === 'production'){

  ReactPixel.init('401345512807894', {
    autoConfig: true,
    debug: false, 
  });
  
  ReactPixel.pageView(); 

}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
