import './App.css';

import { useEffect } from 'react'; 

import {ThemeProvider , createTheme } from '@mui/material/styles'

import { RouterProvider } from 'react-router-dom'

import { Provider } from 'react-redux'

import store from './store/store'

import router from './router/router'

import ReactPixel from 'react-facebook-pixel'

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const theme = createTheme({
  palette: {
    primary: {
      light: '#4c4e64de',
      main: '#e89210', //'#f44336'
      dark: '#eb9500',
      contrastText: '#1f1f1f',
    },
    secondary: {
      light: '#9e9e9e',
      main: '#bdbdbd',
      dark: '#002884',
      contrastText: '#000',
    },
    basicsColors: {
      white: '#ffffff',
      black: '#1F1F1F',
      gradientBrand: 'linear-gradient(90deg, rgba(241,194,14,1) 40%, rgba(232,146,16,1) 81%)'
    }
   
  },
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif'
    ].join(','),
  },
  breakpoints: {
    values: {
      mobile: 520,
      tablet: 880,
      laptop: 1024,
      desktop: 1280,
    },
  },
})


function App() {

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
