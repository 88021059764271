import { lazy , Suspense } from 'react'

import { createBrowserRouter } from 'react-router-dom'


import LoadingCustom from '../components/client/LoadingCustom';

const AdminRouter = lazy(()=>import('./AdminRouter'))

const LoginPage = lazy(()=>import('../pages/LoginPage'))

const ListProduct = lazy(()=>import('../components/ListProduct'))

const ListCategory = lazy(()=>import('../components/ListCategory'))

const ListIngredient= lazy(()=>import('../components/ListIngredient'))

const ListClient = lazy(()=>import('../components/ListClient'))

const ListCoupon = lazy(()=>import('../components/ListCoupon'))

const ListCurrentOrder = lazy(()=>import('../components/ListCurrentOrder'))

const ListOrders = lazy(()=>import('../components/ListOrders'))

const ShoppingPage = lazy(()=>import('../pages/ShoppingPage'))

const OrderOverview = lazy(()=>import('../components/client/OrderOverview'))

const SettingsPage= lazy(()=>import('../pages/SettingsPage'))

const OrderRating = lazy(()=>import('../components/client/OrderRating'))

const NotFoundPage = lazy(()=>import('../pages/NotFoundPage'))

const TermsPage = lazy(()=>import('../pages/TermsPage'))

const PrivacyPolicyPage = lazy(()=>import('../pages/PrivacyPolicyPage'))

const StatisticPage = lazy(()=>import('../pages/StatisticPage'))

const Authorization = lazy(()=>import('../components/authorization/Authorization'))

const AuthorizationShoppingPage = lazy(()=>import('../components/authorization/AuthorizationShoppingPage'))

const router = createBrowserRouter([
    {
      path: '/admin',
      element: <Suspense fallback={<LoadingCustom open={true} />}> 
                  <AdminRouter />
               </Suspense>,
      children: [
        {
          path: '/admin/productos',
          element: <Suspense fallback={<LoadingCustom open={true} />}>
                      <ListProduct />
                   </Suspense>
        },
        {
          path: '/admin/categorias',
          element: <Suspense fallback={<LoadingCustom open={true} />}> <ListCategory /> </Suspense>
        },
        {
            path: '/admin/ingredientes',
            element: <Suspense fallback={<LoadingCustom open={true} />}><ListIngredient/></Suspense>
        },
        {
          path: '/admin/clientes',
          element: <Suspense fallback={<LoadingCustom open={true} />}><ListClient/></Suspense>
        },
        {
          path: '/admin/cupones',
          element: <Suspense fallback={<LoadingCustom open={true} />}> <ListCoupon/> </Suspense>
        },
        {
          path: '/admin/ordenes',
          element: <Suspense fallback={<LoadingCustom open={true} />}><ListCurrentOrder/>  </Suspense> 
        },
        {
          path: '/admin/estadisticas',
          element: <Suspense fallback={<LoadingCustom open={true} />}> <ListOrders/> </Suspense>
        },
        {
          path: '/admin/cliente',
          element: <Suspense fallback={<LoadingCustom open={true} />}> <Authorization><StatisticPage /> </Authorization> </Suspense>
        },
        {
          path: '/admin/configuracion',
          element: <Suspense fallback={<LoadingCustom open={true} />}> <SettingsPage /> </Suspense>
        }
      ]
    },
    { 
      path : '/login',
      element: <Suspense 
                  fallback={<LoadingCustom open={true} />}> 
                    <LoginPage />
                </Suspense>
    
    },
    {
      path: '/',
      element: <Suspense fallback={<LoadingCustom open={true} />}> <AuthorizationShoppingPage> <ShoppingPage /> </AuthorizationShoppingPage> </Suspense>
    },
    {
      path: '/pedir/:slug',
      element: <Suspense fallback={<LoadingCustom open={true} />}> <AuthorizationShoppingPage> <ShoppingPage /> </AuthorizationShoppingPage> </Suspense>
    },
    {
      path: '/ordenes/:id/:slug/:hash',
      element: <Suspense fallback={<LoadingCustom open={true} />}> <OrderOverview /> </Suspense>
    },
    {
      path: '/calificacion/:id/:slug/:hash',
      element: <Suspense fallback={<LoadingCustom open={true} />}> <OrderRating /> </Suspense>
    },
    {
      path: '/terminos/:slug',
      element: <Suspense fallback={<LoadingCustom open={true} />}> <TermsPage /> </Suspense>
    },
    {
      path: '/privacidad/:slug',
      element: <Suspense fallback={<LoadingCustom open={true} />}> <PrivacyPolicyPage /> </Suspense>
    },
    {
      path: '*',
      element: <Suspense fallback={<LoadingCustom open={true} />}><NotFoundPage/></Suspense>
    }
])

export default router

