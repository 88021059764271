import {  createSlice } from '@reduxjs/toolkit'

export const orderSlice = createSlice({
    name: 'orders',
    initialState : {
        currentOrders: [],
        currentRatings: [],
        viewCurrentOrder: {},
        toggleDrawerCurrentOrder: false,
        loadingFetch: {
            isLoading: false,
            messageAlert: '',
            showPopup: false,
            severity: 'success'
        },
        filters: {
            status: {
                pending: {name: 'Pendientes' , state: true},
                accepted: {name: 'Aceptados' , state: true},
                in_preparation: {name: 'En Preparacion' , state: true},
                in_delivery: {name: 'En entrega' , state: false},
            },
            priority:{
                high: {name: 'Alta' , state: true},
                normal: {name: 'Normal' , state: true},
                low: {name: 'Baja' , state: true},
            }
        },
        rating:{
            products:[],
            general_comments:'',
        }
    },
    reducers: {
        chargeCurrentOrders : ( state , action ) => {
            state.currentOrders = action.payload
        },
        chargeCurrentRatings: (state,action) => {
            state.currentRatings = action.payload
        },
        toggleDrawerOrder : ( state ) => {
            state.toggleDrawerCurrentOrder = !state.toggleDrawerCurrentOrder
        },
        setCurrentOrder: ( state , action ) => {
            state.viewCurrentOrder = action.payload
        },
        updateRatingOrder: ( state , action ) => {
            state.rating = { ...state.rating , ...action.payload}            
        },
        changeCheckedFilter : ( state , action ) => {
            state.filters = { ...state.filters , ...action.payload }
        },
        setLoadingFetch: ( state , action ) => {
            state.loadingFetch = { ...state.loadingFetch , ...action.payload}   
        }
    }
})

export const { chargeCurrentOrders , 
               chargeCurrentRatings , 
               toggleDrawerOrder , 
               setCurrentOrder , 
               changeCheckedFilter , 
               updateRatingOrder,
               setLoadingFetch
            } = orderSlice.actions