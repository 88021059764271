import { configureStore } from '@reduxjs/toolkit'

import { authSlice } from './auth/authSlice'
import { shoppingSlice } from './shopping/shoppingSlice'
import { orderSlice } from './orders/orderSlice'

export default configureStore({
    reducer: {
        auth : authSlice.reducer,
        shopping: shoppingSlice.reducer,
        orders: orderSlice.reducer
    }
})