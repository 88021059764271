import { createSlice } from '@reduxjs/toolkit'

export const shoppingSlice = createSlice({

    name : 'shopping' ,
    initialState : {
        openCart : false,
        openCheckout : false,
        openCoupon: false,
        openTip: false,
        disableNextButton: false,
        popupNotification: {
            open: false,
            title: 'Alert Popup',
            body: 'Body Popup',
        },
        cartItems : [],
        coupons:  [],
        order:{
            shop: '6354bfe842e3f31e1a9002d7',
            comments: '',
            products: [],
            coupons: [],
            payment_type: 'cash',
            payment_detail: {},
            payment_status: 'pending',
            price_home_delivery: 0,
            client : {
                name:'',
                phone :'',
                email:''
            },
            delivery_type:{
                type: 'for_table',
                detail: ''
            },
            price:{
                total_order: 0, // Total order products
                tip:0,
                taxes: []
            },
            total: 0, //Temporal
        },
        categories : [],
        loadingCategories: true
    },

    reducers : {

        addCartItems : ( state , action ) => {

            let isUpdated = false

            const cartItems = state.cartItems.map((product) => {
                if(  product._id === action.payload._id && product.comments === action.payload.comments ){
                    isUpdated = true
                    return {...product , quantity: product.quantity + action.payload.quantity}
                }else{
                    return product
                }
            })

            if( isUpdated ){
                state.cartItems = [...cartItems]
            }else{
                state.cartItems.push( {id_cart: state.cartItems.length + 1  , ...action.payload })
            }

            state.order.products = state.cartItems.map((product) => {
                return {comments: product.comments , product: product._id , quantity: product.quantity}
            })
        },

        removeCartItem : ( state , action ) => {
            state.cartItems = state.cartItems.filter( (product) => product.id_cart !== action.payload )
            state.order.products = state.cartItems.map((product) => {
                return {comments: product.comments , product: product._id , quantity: product.quantity}
            })
        },

        addCoupon : ( state , action ) => {
            state.coupons.push( {id_cart: state.coupons.length + 1  , ...action.payload })
            state.order.coupons = state.coupons.map((coupon) => {
                return coupon._id
            })
        },

        removeCoupon : ( state , action ) => {
            state.coupons = state.coupons.filter( (coupon) => coupon.id_cart !== action.payload )
            state.order.coupons = state.coupons.map((coupon) => {
                return coupon._id
            })
        },

        updateOrder : ( state , action ) => {
            state.order = { ...state.order , ...action.payload }
        },

        toggleCart : ( state ) => {
            state.openCart = !state.openCart
        },

        toggleCheckout : ( state ) => {
            state.openCheckout = !state.openCheckout
        },

        updatePopupNotification : ( state , action ) => {
            state.popupNotification = { ...state.popupNotification , ...action.payload }
        },

        toggleCoupon: (state ) => {
            state.openCoupon = !state.openCoupon
        },
        toggleTip: (state) =>{
            state.openTip = !state.openTip
        },
        setCartItems: ( state , action ) => {
            state.cartItems = action.payload
            state.order.products = state.cartItems.map((product) => {
                return {comments: product.comments , product: product._id ,quantity: product.quantity}
            })
        },

        setLoadingCategories: ( state , action ) => {
            state.loadingCategories = action.payload
        },

        setCategories : ( state , action ) => {
            state.categories = action.payload
        },

        disableNextButton: (  state , action ) => {
            state.disableNextButton = action.payload;
        }

    },


})


export const { addCartItems,
               toggleCart, 
               setCategories, 
               removeCartItem,
               updateOrder,
               toggleCheckout,
               setCartItems,
               toggleCoupon,
               toggleTip,
               addCoupon,
               removeCoupon,
               updatePopupNotification,
               setLoadingCategories,
               disableNextButton
            } = shoppingSlice.actions