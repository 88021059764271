import React from 'react'

import { Box , CircularProgress , Backdrop , Typography } from '@mui/material'

const LoadingCustom = ({ message='' , open }) => {
  return (
    <Backdrop
        sx={{ color: '#fff', zIndex: 1000000}}
        open={open}
    >
        <CircularProgress color="inherit" />
        <Box>
            <Typography sx={{ml:3}}>{ message }</Typography>
        </Box>
    </Backdrop>
  )
} 
 
export default LoadingCustom
