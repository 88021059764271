  import  { createSlice } from '@reduxjs/toolkit'

export const  authSlice = createSlice({

    name: 'auth',

    initialState: {
        status : 'not-authenticated',
        user : null, // { } model mongo
        token: null,
        error: null, 
    },

    reducers: {

        login : ( state , action ) => {

            state.status = 'authenticated'
            state.user = action.payload.user
            state.token = action.payload.token
            state.error = null
            
        },

        logout : ( state , action ) => {
        },

        setError : ( state , action ) => {
            state.error = 'Authentication Error'
        }

    }


})

export const { login , logout , setError  } = authSlice.actions